<template>
  <div>
    <van-cell
        v-for="(val, key) in cellList"
        :border="false"
        :key="key"
        :title="val.title"
        :value="val.value || '-'"
    />
    <van-cell title="更多信息">
      <router-link
          style="color: var(--mainColor)"
          :to="`/hoss/clientRecord?clientId=${$route.query.clientId}`"
      >
        查看
      </router-link>
    </van-cell>
  </div>
</template>

<script>
import {getClientInfo} from "@/api/hoss";
import {getAge} from "@/utils";

export default {
  data() {
    return {
      cellList: {
        name: {title: "昵称（姓名）", value: ""},
        hpId: {title: "会跑号", value: ""},
        age: {title: "年龄", value: ""},
        phone: {title: "联系电话", value: ""},
        homeAddress: {title: "家庭住址", value: ""},
      },
    };
  },
  async created() {
    const {value} = await getClientInfo(this.$route.query.clientId);
    const {name, nickName, hpId, phone, address, city, district, province, birthday} = value;
    let homeAddress = "";
    // 用birthday计算年龄，birthday是毫秒
    const age = getAge(birthday)[0];

    if (district && address) {
      homeAddress = `${province}，${city}，${district}，${address}`;
    } else if (district) {
      homeAddress = `${province}，${city}，${district}`;
    } else if (address) {
      homeAddress = address;
    }

    const info = {
      name: name ? `${nickName}（${name}）` : `${nickName}`,
      hpId,
      age,
      phone,
      homeAddress,
    };

    for (const key in info) {
      this.cellList[key].value = info[key];
    }
  },
};
</script>

<style scoped lang="less">
.van-cell {
  padding: 3px 0;
  font-size: 12px;

  .van-cell__title {
    flex: 0 1 100px;
    color: var(--subTitleColor);
  }

  .van-cell__value {
    text-align: left;
    color: var(--titleColor);
  }
}
</style>
